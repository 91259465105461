import React from 'react';
import classNames from 'classnames';
import { NamedLink } from '../../../../components';
import { categories, commonDropDownData, brandsData } from '../../../../util/constants';
import algoliaInsights from 'search-insights';
import css from './TopbarDesktop.module.css';

const DOLCE_GABBANA = 'Dolce Gabbana';
const FILTER_VIEWED = 'Filter Viewed';
const SELL_ALL = 'See All';
const MATCHING_SET = 'Matching Set';

algoliaInsights('init', {
  appId: process.env.REACT_APP_APPLICATION_ID,
  apiKey: process.env.REACT_APP_ALGOLIA_ADMIN_API_KEY,
});

export const handleRedirectTosearch = ({ link, currentUser, redirect, title, type }) => {
  // Add algolia events.
  algoliaInsights('clickedFilters', {
    userToken: currentUser && currentUser.id ? currentUser.id.uuid : 'anonymous-user',
    eventName: FILTER_VIEWED,
    index: process.env.REACT_APP_ALGOLIA_LISTING_INDEX,
    filters: [`${title}:${link}`],
  });

  switch (type) {
    case 'Brands':
      return redirect.push(
        link == 'All Brands'
          ? '/search'
          : `/search?refinementList%5BpublicData.brand.label%5D%5B0%5D=${link}`
      );
    case 'Girls':
      return link == MATCHING_SET
        ? redirect.push(
            `/search?refinementList%5BpublicData.categoryLevel1.Label%5D%5B0%5D=${title}&refinementList%5BpublicData.gender.label%5D%5B0%5D=Girl`
          )
        : redirect.push(
            `/search?refinementList%5BpublicData.categoryLevel1.Label%5D%5B0%5D=${title}&refinementList%5BpublicData.categoryLevel2.Label%5D%5B0%5D=${title} > ${link}&refinementList%5BpublicData.gender.label%5D%5B0%5D=Girl`
          );
    case 'Boys':
      return link == MATCHING_SET
        ? redirect.push(
            `/search?refinementList%5BpublicData.categoryLevel1.Label%5D%5B0%5D=${title}&refinementList%5BpublicData.gender.label%5D%5B0%5D=Boy`
          )
        : redirect.push(
            `/search?refinementList%5BpublicData.categoryLevel1.Label%5D%5B0%5D=${title}&refinementList%5BpublicData.categoryLevel2.Label%5D%5B0%5D=${title} > ${link}&refinementList%5BpublicData.gender.label%5D%5B0%5D=Boy`
          );
    case 'Baby':
      return link == MATCHING_SET
        ? redirect.push(
            `/search?refinementList%5BpublicData.categoryLevel1.Label%5D%5B0%5D=${title}&refinementList%5BpublicData.isBabyCategory%5D%5B0%5D=true`
          )
        : redirect.push(
            `/search?refinementList%5BpublicData.categoryLevel1.Label%5D%5B0%5D=${title}&refinementList%5BpublicData.categoryLevel2.Label%5D%5B0%5D=${title} > ${link}&refinementList%5BpublicData.isBabyCategory%5D%5B0%5D=true`
          );
    default:
      return redirect.push('/search');
  }
};

// Define the Category component
const Category = ({ title, links, index, type, redirect, currentUser }) => {
  return (
    <div
      key={index}
      className={classNames(
        index == 0 && css.LgRow,
        index == commonDropDownData.length - 1 && css.lastItem,
        css.dropDownItems,
        type == 'Brands' && css.brands
      )}
    >
      <h3>{title}</h3>
      <ul>
        {links.map((link, index) => {
          if (link == SELL_ALL || link == MATCHING_SET) {
            return (
              <>
                {link == MATCHING_SET ? (
                  <li
                    key={index}
                    className={css.seeAllLink}
                    onClick={() =>
                      handleRedirectTosearch({ link, currentUser, redirect, title, type })
                    }
                  >
                    {link}
                  </li>
                ) : (
                  <NamedLink key={index} className={css.seeAllLink} name={'AlgoliaSearchPage'}>
                    {link}
                  </NamedLink>
                )}
              </>
            );
          } else {
            return (
              <li
                key={index}
                onClick={() => handleRedirectTosearch({ link, currentUser, redirect, title, type })}
              >
                {link == DOLCE_GABBANA ? link.split(' ').join(' & ') : link}
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

const CategoriesDropdownMenu = props => {
  const { type, listingCategories, redirect, currentUser } = props;

  const mappingData = Array.isArray(categories) && categories.find(item => item.type == type)?.data;

  return (
    <div>
      <div
        style={{ display: 'flex', justifyContent: 'space-between' }}
        className={classNames(type == 'Brands' && css.brandsWrapper)}
      >
        {mappingData.map((category, index) => (
          <Category
            key={index}
            title={category.title}
            links={category.links}
            index={index}
            type={type}
            redirect={redirect}
            currentUser={currentUser}
          />
        ))}
      </div>
    </div>
  );
};

export default CategoriesDropdownMenu;
